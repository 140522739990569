<script>
	import { onMount } from 'svelte'
	import Auth from './lib/auth.js'
	import resources from './lib/resources.js'
	import events from './lib/events.js' 
	import Task from './lib/task.js'
	import Tasks from './lib/tasks.js'
	import net from './lib/net.js'

	let errors=[],
		taskName='', processing = false,
		task = {id:''}, tasks = [], auth = [],
		resTypes = Object.keys(resources),
		saveTimeout

	$: Task.set(task)

	function resGetIconType(type) {
		let icon = 'fa fa-bs'
		Object.keys(resources).forEach(function (resType) {
			if(resType==type) icon = resources[resType].icon
		})
		return icon
	} 

	function taskSave(task) {
		clearTimeout(saveTimeout)
		saveTimeout = setTimeout(() => { Task.save(task) }, 1000)
	}

	onMount(() => {
		let tid = window.location.href.match(/\/i\/(.+)\/$/i)
		Task.subscribe((value)=> { task = value; if(task && task.id) { if(task.id != window.location.href.match(/\/i\/(.+)\/$/i)) history.pushState({}, null, '/i/' + task.id + '/')}} )
		Tasks.subscribe((value)=> {tasks = value})
		Auth.subscribe( (value) => { auth = value})
		Task.create()
		
		if(tid) Task.load(tid[1])

		events.initEvents() 
		Tasks.load() 
	})	
</script>

<style>
#bs-form {
	max-width:550px;
}
</style>  


<div id="bs-form">

	<div  class="d-flex p-3 justify-content-around">
		<div>
			{#if auth.display}
			 {auth.display}
			 <a href="/" on:click|preventDefault={(ev)=>{Auth.doEmailAuth()}} >(change)</a>
			{:else}
				<a href="/" on:click|preventDefault={(ev)=>{Auth.doEmailAuth()}} >login/save...</a>
			{/if}
		</div>
		
		<div>
		{#if task.id}
			<a href="/" on:click={(ev)=>{ev.preventDefault(); Task.confirmNew();}}>new...</a>
		{/if}
		</div> 
		
	</div>

{#if errors.length > 0}
{	#each errors as error}
		<div class="alert alert-danger">{error}</div>
	{/each}
{/if}

<div class="form-group">
        <input on:keyup={()=>taskSave(task)} bind:value={task.title} type="text" class="form-control form-control-lg" placeholder="what?"></div>
<div id="attributes">

{#if task._r}
{#each task._r as taskResource}
<div class="resource">
	<div class="res-icon">
            <i class="{resGetIconType(taskResource.rtype)}"></i>
	</div>
	<div class="res-mid ml-3">
        {@html resources[taskResource.rtype].display(taskResource)}
	</div>
	<div class="ml-3">
            <a href="/" on:click|preventDefault={()=>Task.destroyResource(taskResource)}><i class="fa fa-close"></i></a></div>
	</div>
{/each} 
{/if}


{#if resTypes.length > 0 }
	<p class="smaller mt-3">suggestions</p> 
{/if}
{#each resTypes as resType}
	<div class="resource">
	<div class="res-icon">
				<i class={resources[resType].icon}></i></div>
	<div class="res-mid ml-3">
		<a href="/" on:click|preventDefault={()=>events.toggleResource(resType)} >{resources[resType].name}</a>
		<span id="{resType}-input" class="d-none">{@html resources[resType].html(resType)}</span>
	</div>
</div>
	
{/each}



	<div class="mt-5 mb-2 smaller">bullshit</div>


{#each tasks as userTask}
	<div class="card" on:click={()=>{Task.load(userTask['id'])}}>
			<div class="card-body {userTask.id == task.id ? 'card-selected' : '' }">
				<h5 class="card-title">{userTask['title']}</h5>
			</div>
		</div>
{/each}
</div>

</div>

