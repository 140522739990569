import net from './net.js'
import {writable} from 'svelte/store'


let doReactive = true, needsSave = false, taskStore = writable(), task = {}, isSaving = false

taskStore.subscribe((value) => {task = value})

function create(){ taskStore.set( {id:'', type: 'task', title:'', _r: [] } ) }

function set(updatedTask) {
    task = updatedTask
}

function confirmNew() {
    let doIt = true
    if(needsSave) {
        doIt = confirm('current task not saved. r u sure?')
    }
    if(doIt) { 
        create()
        history.pushState({}, null, '/');
    }
}

function saveResources() {
    if (task.id && doReactive) {        
        net.commit(task.resources)
    }
}

function save(task) {
    taskStore.set(task)
    net.commit(task).then((data) => {
        data.forEach( (resp) => {
            task.id = resp.id
            taskStore.set(task)
        })
        
    })
}

function load(id) {
    doReactive = false
    net.dataLoad({id, type:'task'},function(data) {
        // data loaded, so load resources
        if(data) {
            taskStore.set(data)
            doReactive = true
            history.pushState({}, null, '/i/' + task.id + '/')
            window.scrollTo(0,0)
        }
    })
}

function destroyResource(tr) {
    if(confirm('really?')) {
        let index = task._r.indexOf(tr)
        if(index !== -1) {
            task._r.splice(index, 1)
            taskStore.set(task)
            save(task)
        }
    }
}

function subscribe(cb) {
    taskStore.subscribe(cb)
}

function addResource(res) {
    task._r.push(res)
    taskStore.set(task)
    save(task)
}

function saveResource(res) {
    
}

export default {
    addResource,
    create,
    save,
    saveResources,
    load,
    destroyResource,
    subscribe,
    confirmNew,
    set
} 