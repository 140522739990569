import net from './net.js'
import utils from './utils.js'
import {writable} from 'svelte/store'

let authStore = writable()

let auth = {
    interruptAuth: false,
    display: window.taskUser,
    vid: window.taskVid,
    email: '',
    verifyToken: '',    
}

authStore.set(auth)

const getEmailFromUser = () => {
    return new Promise(function(resolve, reject) {
        auth.email = window.prompt(`email address (no spam)`) 
        if (!utils.isEmail(auth.email)) { 
            alert('not an email')
            auth.interruptAuth = true
        } else {
            auth.email = auth.email.toLowerCase()
        }
        resolve()
      })
}


const getTokenFromUser = () => {
    return new Promise(function(resolve, reject) {
        resolve(auth.verifyToken = window.prompt(`check inbox for code ${auth.token}`))
      })
}


const validateToken = (token) => {
    if(auth.verifyToken===null) return
    net.doGet(`/wp-json/task/v1/validateAuthToken?token=${auth.verifyToken}`, (result) => {
        if(result.data && result.data.retry) {
            getTokenFromUser().then((result) => {
                validateToken(result)
            })
        }
        if(result.display) {
            auth.display = result.display
            authStore.set(auth)
        }
    })
}

export default {
    doEmailAuth: () => {
        getEmailFromUser().then((result) => {
            if (!auth.interruptAuth) {
                net.doGet(`/wp-json/task/v1/sendAuthToken?email=${auth.email}&vid=${auth.vid}`, (result) => {
                    auth.token = result.token
                    getTokenFromUser().then((result) => {
                        validateToken(result)
                    })
                })
            } else {
                auth.interruptAuth = false
            }
        })
    },
    user: () => { return auth.display },
    isLoggedOn: () => { return auth.user() ? true : false },
    subscribe: (cb) => { authStore.subscribe(cb) },
}