import net from './net.js'
import { writable } from 'svelte/store'

let doReactive = true, needsSave = false, tasks, tasksStore = writable([])

tasksStore.subscribe((value) => {tasks = value})

function create(){ tasksStore.set( [] ) }

function load() {
    fetch('/wp-json/task/v1/tasks', {method: 'GET', headers: {
  'Content-Type': 'application/json'}})
        .then(
            function(response) {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                }
            // Examine the text in the response
            response.json().then(function(data) {
                tasksStore.set(data)
            });
            }
        )
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });

}

function destroy(task) {
    net.dataDeletes([task])
}


function subscribe(cb) {
    tasksStore.subscribe(cb);
}

export default {
    create,
    load,
    subscribe,
    destroy
}