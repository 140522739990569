import events from './events.js'
import task from './task.js'

export default {

	url: 
		{ 
			name: 'URL',
			icon: 'fa fa-link',
			display: function (tr) {
				return `<a href="${tr.url}" target="_blank">${tr.title.length>0 ? tr.title : tr.url}</a>`
				},
			html: function (type) { console.log(this)
			return `
					<div>
						<input type="text" class="form-control" placeholder="url: http://blablablab..." name="input-${type}-url">
						<input type="text" class="form-control" placeholder="title: link to xyz" name="input-${type}-title">
						<a href="/" id='event-${type}-click-ok' class="btn btn-primary">ok</a>
					</div>
				`},
			events:{ 
				ok: function(ev) {
					events.defaultOk(ev)
				}
			}
		},
		gdoc: 
		{ 
			name: 'Google Doc', 
			icon: 'fa fa-file-word-o',
			display: function (tr) {
				return `<a href="${tr.url}" target="_blank">${tr.title.length>0 ? tr.title : tr.url}</a>`
				},
			html: function (type) {
			return `
					<div>
						<input type="text" class="form-control" placeholder="title: your doc name" name="input-${type}-title">
						<a href="/" id='event-${type}-click-ok' class="btn btn-primary">new</a>
						<a href="/" id='event-${type}-click-existing' class="btn btn-primary">existing</a>
					</div>
				`},
			events:{ 
				existing: function(ev) {
					gapiSelectDoc(ev, 'DOCUMENTS')
				},
				ok: function(ev) {
					gapiDoNewDoc(ev, 'application/vnd.google-apps.document')
				}
			}
		}
	
}



	

function gapiSelectDoc(ev, viewType) {
    gapi.load('picker', function() {
                    var picker = new google.picker.PickerBuilder().
                    addView(google.picker.ViewId[viewType]).
                    addView(google.picker.DocsViewMode.LIST).
                    setOAuthToken(gapi.auth.getToken().access_token).
                    setDeveloperKey('AIzaSyCJo3l-hPs6wnDoJPBOQ3fpLv_DH4q9Two').
                    setMaxItems(1).
                    disableFeature(google.picker.Feature.NAV_HIDDEN).
                    hideTitleBar().
                    setCallback(function(results){
                        if(results.action=='picked') {					
                            ev.tr.title = results.docs[0].name
                            ev.tr.url = results.docs[0].url
                            events.defaultOk(ev)
                        }
                    console.log(results);} ).
                    build();
                    picker.setVisible(true);
                });
}

function gapiDoNewDoc(ev, mimeType) {
	let doNewDoc = function (isSignedIn) {
		console.log('changed to ' + isSignedIn)
                    if(isSignedIn) {
                        gapi.client.load('drive', 'v2', function() {
                    var request = gapi.client.request({
                        'path': '/drive/v2/files',
                        'method': 'POST',
                        'body':{
                            mimeType,
                            "title" : ev.tr.title,
                            "description" : "Created by Bullshit.do"
                        }
                    });
                    request.execute(function(resp) { 
                        ev.tr.url = resp.alternateLink
                        events.defaultOk(ev) 
                    });
                    })
	}}
    if(gapi.auth2.getAuthInstance().isSignedIn.get()) { doNewDoc(true) }else{ gapi.auth2.getAuthInstance().isSignedIn.listen(doNewDoc) }
    gapi.auth2.getAuthInstance().signIn()
}