let taskStatus = 'saved'
let needsSave = false
function commit(obj) {
    return new Promise(function(resolve, reject) {
        if (!Array.isArray(obj)) obj = [obj]
    
        if (taskStatus == 'saving') {
            needsSave = true
        } else {
            needsSave = false
        }
        if (taskStatus != 'saving') {
            taskStatus = 'saving'
            fetch('/wp-json/task/v1/commits', {method: 'POST', body: JSON.stringify(obj), headers: {
    'Content-Type': 'application/json'}})
            .then(
                function(response) {
                    if (response.status !== 200) {
                        console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                        needsSave = true
                        taskStatus = 'saveFailed'
                        reject('failed');
                    } else { 
                        taskStatus ='saved' 
                    }
                    // Examine the text in the response
                    response.json().then(function(data) {
                        data.forEach((item, num) => {
                                obj[num].id = item.id
                            })
                        if (needsSave) {
                            needsSave = false
                            commit(obj)
                        }
                        console.log(data);
                        resolve(data)
                    });
                }
            )
            .catch(function(err) {
                console.log('Fetch Error :-S', err);
                needsSave = true
                taskStatus = 'saveFailed'
            });
        }
        
      })
}


function dataLoad(args, callback) {
    fetch('/wp-json/task/v1/postById', {method: 'POST', body: JSON.stringify(args), headers: {
  'Content-Type': 'application/json'}})
        .then((response) => { response.json().then(function(data) {
                    callback(data)
            })})
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
        
}

function doGet(url, callback) {
    fetch(url, {method: 'GET'})
        .then((response) => { response.json().then(function(data) {
                    callback(data)
            })})
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
        
}


function dataDeletes(obj, callback) {
    callback = callback || function() {}
            fetch('/wp-json/task/v1/deletes', {method: 'POST', body: JSON.stringify(obj), headers: {
  'Content-Type': 'application/json'}})
        .then((response) => { response.json().then(function(data) {
                    callback(data)
            })})
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });

}

export default {
    doGet,
    dataLoad,
    dataDeletes,
    commit
}