import resources from './resources'
import Task from './task'
import { tick } from 'svelte'

let task 

Task.subscribe((v)=>{task = v})

let events = {
    defaultOk: (ev) => {
		ev.preventDefault()
		doValidate(ev.tr.rtype)
		doDefaultOk(ev)
    },
    toggleResource: (type) => {
        toggleResource(type)
    },
    initEvents: () => {
        wireEvents()
    }
}

function doDefaultOk(ev) {
    Task.addResource(ev.tr)
    toggleResource(ev.tr.rtype)
}


function toggleResource(type) {
    let el = document.getElementById(type+'-input')
    el.classList.toggle('d-none')
    if(!el.classList.contains('d-none')) {
        if (typeof resources[type].invoke !== 'undefined') {
            resources[type].invoke()
        }
    }
    
}

function doValidate(type) {

}



function wireEvents() {
    console.log('called wire up')
    tick()
    document.querySelectorAll('[id^="event-"]').forEach(el => {
        try{
            let res = getEventParts(el)
            el.addEventListener(res.event, function(ev) {ev.preventDefault(); ev.tr={}; ev.tr.rtype=res.rtype; ev = eventTriggered(ev); resources[res.rtype].events[res.cb](ev);})
        } catch (e) {
            console.log(e)
        }
    });
}

function eventTriggered(ev) {
    ev = eventAddInputsToEvent(ev)
    return ev
}

function eventAddInputsToEvent(ev) {
    let inputs = eventGetInputs(ev.tr.rtype)
    Object.keys(inputs).forEach((key) => {
        if(typeof ev.tr[key]==='undefined') ev.tr[key] = inputs[key]
    })
    return ev
}

function eventGetInputs(type) {
    let inputs = {}
    document.querySelectorAll(`[name^='input-${type}-']`).forEach((el) => {
        let c = getInputParts(el)
        inputs[c.name] = c.value
    })
    return inputs
}

function getEventParts(el) {
    let parts = el.id.split('-')
    return {rtype: parts[1], event: parts[2], cb: parts[3] }
}

function getInputParts(el) {
    let parts = el.name.split('-')
    return {name: parts[2], rtype: parts[1], value: el.value}
}




 
export default events
